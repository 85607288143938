import { fallbackSettings } from '@/api/setup/setup-frontend';
import { MusicPage } from '@/components/_pages/MusicPage';
import {
  getMusicPageCacheKey,
  getMusicPageData,
  getMusicPageHeadings,
} from '@/components/_pages/MusicPage/util';
import { HOUR_INTERVAL } from '@/constants/revalidationInterval';
import { staticComprehensiveListingsMusic } from '@/fixtures/staticComprehensiveListings';
import { HypertuneHydrator } from '@/generated/hypertune.react';
// import { getComprehensiveListings } from '@/hooks/useComprehensiveListings';
import getHypertune, { userDataDefault } from '@/lib/getHypertune';

import type {
  HomePageProps,
  HomePagePropsWithHypertune,
} from '@/components/_pages/MusicPage/types';
import type { GetStaticProps, NextPage } from 'next';

const HomePage: NextPage<HomePagePropsWithHypertune> = (
  props: HomePagePropsWithHypertune,
) => {
  const { serverDehydratedState, serverRootArgs, ...rest } = props;

  return (
    <HypertuneHydrator
      dehydratedState={serverDehydratedState}
      rootArgs={serverRootArgs}
    >
      <MusicPage {...rest} />
    </HypertuneHydrator>
  );
};

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  const hypertune = await getHypertune({ userData: userDataDefault });

  // TODO: Uncomment when backend API is ready
  // const isDynamicComprehensiveListingEnabled =
  //   hypertune.dynamicComprehensiveListing({ fallback: false });

  const settings = fallbackSettings;

  const initialCacheKey = getMusicPageCacheKey(settings);
  const initialData = await getMusicPageData();

  // TODO: Uncomment when backend API is ready
  // const comprehensiveListings = isDynamicComprehensiveListingEnabled
  //   ? await getComprehensiveListings('music')
  //   : staticComprehensiveListingsMusic;
  const comprehensiveListings = staticComprehensiveListingsMusic;

  const initialHeadingsData = getMusicPageHeadings();

  const serverDehydratedState = hypertune.dehydrate();
  const serverRootArgs = hypertune.getRootArgs();

  if (
    initialData?.carousels.discover?.length === 0 ||
    initialData?.carousels.homepage?.length === 0
  ) {
    throw new Error('Carousel data not found.');
  }

  return {
    revalidate: HOUR_INTERVAL,
    props: {
      initialCacheKey,
      initialData,
      initialHeadingsData,
      comprehensiveListings,
      serverDehydratedState,
      serverRootArgs,
    },
  };
};

export default HomePage;
